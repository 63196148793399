import './dashboard.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Form} from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {useHistory} from 'react-router-dom';

function DashboardLimo(props) {
    let history = useHistory();
    const [source, setSource] = useState([]);
    useEffect(() => {
        fetch(global.url + "viewAllCompany", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log('responseJson', responseJson)
            setSource(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
	}, []);
  return (
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden',}}>
                <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Dashboard</p>
                </div>
                <div style={{margin: 30}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p className="onboard-companies" style={{color: '#000', fontSize: 16}}>OUR COMPANIES</p>
                        <Button variant="primary" style={{height: 25, paddingTop: 3, fontSize: 13, border: 'none', fontWeight: 'bold', boxShadow: 'none', backgroundColor: '#7e77fe', color: '#fff'}}>
                            <a style={{textDecoration: 'none', color: '#fff'}} href="/ledgerdetailsmetro">Ledger Details</a>
                        </Button>
                    </div>
                  
                    <Row>
                        <Col md={4}>
                                <FormControl style={{marginTop: 5}} sx={{width: '100%'}}>
                                    <TextField  id="outlined-basic" variant="outlined"  size="small" label="Search Company" />
                                </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        {source.map((item, index) => (
                            <Col xs={12} md={6}>
                            <a style={{color:'unset', textDecoration: 'none'}}>
                            <div className="second-box" style={{marginTop: 20}}>
                                <Row>
                                    <Col xs={12} sm={10}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <p className="box-para1">{item.company_name}</p>
                                        </div>
                                        <Row>
                                            <Col xs={6}>
                                                <Row>
                                                    <Col md={8}>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Today Sale Amount</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Total Sale Amount</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Today Commision</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Total Commision</p>
                                                    </Col>
                                                    <Col md={4}>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={6}>
                                            <Row>
                                                    <Col md={8}>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Today Cancel Order</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Today Pending Order</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Total Order</p>
                                                    </Col>
                                                    <Col md={4}>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            </a>
                        </Col>
                        ))}
                    </Row>
                </div>
            </Paper>
        </div>
  );
}

export default DashboardLimo;



















