import './App.css';
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import AppNavigator from './router';
import './fonts/BAHNSCHRIFT 10.TTF';

function App() {

  // global.url = "http://metrofood.nasscript.com/erp/public/api/";
  // global.images = "http://metrofood.nasscript.com/erp/public/";
  // global.excell = "http://metrofood.nasscript.com/erp/storage/app/";

  // global.proname = "Fetch"
  // global.website = "www.fetch.qa"

  // global.url = "http://localhost/connecting-metrofood/public/api/";
  // global.images = "http://localhost/connecting-metrofood/public/";
  // global.excell = "http://localhost/connecting-metrofood/storage/app/";

  global.url = "https://erpbaladna.nasscript.com/erp/public/api/";
  global.images = "https://erpbaladna.nasscript.com/erp/public/";
  global.excell = "https://erpbaladna.nasscript.com/storage/app/";
  return (
      <AppNavigator />
  );
}

export default App;
