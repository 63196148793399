import React, {useState, useEffect} from 'react';
import './accounts.css';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Loader from '../../loader/loader'


const columns = [
    { id: '1', label: 'Voucher Date', minWidth: 50,span: false },
    { id: '2', label: 'Voucher No', minWidth: 50,span: false },
    { id: '3', label: 'Voucher Type', minWidth: 50,span: false },
    // { id: '3', label: 'Code', minWidth: 50,span: false },
    { id: '3', label: 'Accounts', minWidth: 50,span: false },
    { id: '3', label: 'Debit', minWidth: 50,span: false },
    { id: '3', label: 'Credit', minWidth: 50,span: false },
    { id: '3', label: 'Balance', minWidth: 50,span: false },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }
  const sources = [{},{}, {}, {}, {},{}, {}, {}, {},{}, {}, {}, {},{}, {}, {}, {}, {}, {}]

function LedgerdetailsMetro(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")
  const [modal, setModal] = useState(false)
    const [toggle,  setToggle] = useState(false)
  const [age, setAge] = React.useState('');

  const [source, setSource] = React.useState([]);
  const [ledger, setLedger] = React.useState([]);
  const [ledgerName1, setLedgername1] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0)
  const [filterData, setFilterData] = React.useState("");
  const [toDate, setTodate] = React.useState(new Date);
  const [fromDate, setFromdate] = React.useState(new Date);
  const [ledgerName, setLedgername] = React.useState('')
  const [ledgeFilter, setLedgeFilter] = React.useState('')

  const [creditopeningbalance, setCreditopeningbalance] = React.useState(0.00)
  const [creditcurrenttotal, setCreditcurrenttotal] = React.useState(0.00)
  const [creditclosingbalance, setCreditclosingbalance] = React.useState(0.00)

  const [dateTo, setDateTo] = React.useState('')
  const [dateFrom, setDateFrom] = React.useState('')

  const [debitopeningbalance, setDebitopeningbalance] = React.useState(0.00)
  const [debitcurrenttotal, setDebitcurrenttotal] = React.useState(0.00)
  const [debitclosingbalance, setDebitclosingbalance] = React.useState(0.00)
  const [array, setArray] = React.useState(false)


  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  useEffect(() => {
      getData()
      getLedgerName()
    }, []);

    const getData = () => {
        // var userdata = JSON.parse(localStorage.getItem("user"))
        // console.log('user', userdata)
        // var branch = JSON.parse(localStorage.getItem("branch"))
        // var company = JSON.parse(localStorage.getItem("company"))
        // setUsercompanyid(company)
        // setUserbranchid(branch)
        // setUsertype(userdata.user_type)
        // setUserid(userdata.user_id)
        // setLoader(true)
        // var data = {
        //   "type":  userdata.user_type,
        //   "companyid": company
        // }
        // fetch(global.url + "viewLedgerbillReport", {
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data)
        //   })
        //   .then((response)=> response.json())
        //   .then((responseJson)=>{
        //       console.log("response ledger", responseJson)
        //       setLoader(false)
        //       if(responseJson.error == "false"){
               
        //         // setSource(responseJson.data)
        //       }
        //   })
        //   .catch((error)=>{
        //       console.log(error)
        //   })
    }

    const getLedgerName = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "type":  userdata.user_type,
        "companyid": company
      }
    fetch(global.url + "viewLedger", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("response", responseJson)
          if(responseJson.status == "false") {
            setLedger(responseJson.data)
            console.log("response ledger", responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }
    const ledgerNameShow = (event) => {
      setLedgername(event.target.value);
    };

    const ledgerFilter = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid" : company,
        "ledger_id":  ledgerName,
        "voicherfrom": fromDate.toISOString().split('T')[0],
        "voicherto": toDate.toISOString().split('T')[0]
      }
      console.log('data', data)
      fetch(global.url + "viewLedgerbillReportAgainstBalance", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("response Filter", responseJson)
          setLoader(false)
          setModal(false)
          if(responseJson.error == "false"){
            setSource(responseJson.data)
            setLedgername1(responseJson.total_data.selectedLedger)
            setDateFrom(responseJson.total_data.selectedfromdate)
            setDateTo(responseJson.total_data.selectedtodate)
            if(responseJson.total_data.creditordebt == "credit") {
              console.log("credit")
              setCreditopeningbalance(responseJson.total_data.openbalance)
              setCreditclosingbalance(responseJson.total_data.closingbalance)
              setDebitopeningbalance(0)
              setDebitclosingbalance(0)
         
              setLedgeFilter(ledgerName)
            }
            else{
              console.log("debit")
              setCreditopeningbalance(0)
              setCreditclosingbalance(0)
              setDebitopeningbalance(responseJson.total_data.openbalance)
              setDebitclosingbalance(responseJson.total_data.closingbalance)
            }
            if(responseJson.total_data.closingbalanceside == "debit"){
              setCreditclosingbalance(0)
              setDebitclosingbalance(responseJson.total_data.closingbalance)
            }else{
              setCreditclosingbalance(responseJson.total_data.closingbalance)
              setDebitclosingbalance(0)
            }
            setDebitcurrenttotal(responseJson.total_data.debittotal)
            setCreditcurrenttotal(responseJson.total_data.credittotal)
            
            setArray(true)
          }else{
            setArray(false)
            setSource([])
            alert(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    return (
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden'}}>
            <div style={{margin: 20, display: 'flex', justifyContent: 'space-between'}}>
              <div style={{display: 'flex'}}>
                {ledgerName1 == "" ?
                <p style={{fontSize: 16,  marginBottom: 0, fontWeight: 'bold'}}>Ledger Details</p>:
                <p style={{fontSize: 16,  marginBottom: 0, fontWeight: 'bold'}}>Ledger Details: {ledgerName1}</p>}
                {/* {ledgeFilter == "" ? null:
                  <p style={{fontSize: 16,  marginBottom: 0, fontWeight: 'bold'}}> - {ledgeFilter}</p>
                }  */}
              </div>
              <div style={{ display: 'flex'}}>
                {dateFrom == "" ? null : 
                <p style={{fontSize: 16, float: 'right', fontWeight: 700}}>{dateFrom} To {dateTo}</p>}
                <Button onClick={() => setModal(true)} variant="contained" style={{height: 20, fontSize: 10, float: 'right', marginLeft: 20}}>Filter</Button>
              </div>
            </div>

            <div style={{padding: 20, paddingTop: 0, overflowX: 'auto'}}>
              <div style={{display: 'flex', width: '100%',}}>
                <div style={{width: 150, fontSize: 14, fontWeight: 'bold'}}><span>Order Date</span></div>
                <div style={{width: 400, fontSize: 14, fontWeight: 'bold'}}><span>Perticulars</span></div>
                <div style={{width: 150, fontSize: 14, fontWeight: 'bold', textAlign: 'right'}}><span>Order No</span></div>
                <div style={{width: 200, fontSize: 14, fontWeight: 'bold', textAlign: 'center'}}><span>Order Type</span></div>
                <div style={{width: 100, fontSize: 14, fontWeight: 'bold', textAlign: 'right'}}><span>Debit</span></div>
                <div style={{width: 100, fontSize: 14, fontWeight: 'bold', textAlign: 'right'}}><span>Credit</span></div>
                <div style={{width: 150, fontSize: 14, fontWeight: 'bold', textAlign: 'right'}}><span>Balance</span></div>
                <div style={{width: 70, fontSize: 14, fontWeight: 'bold', textAlign: 'right'}}><span>Cr/Dr</span></div>
              </div>
              <hr style={{marginBottom: -8}}></hr>
              <div style={{display: 'flex', width: '100%', overflowX: 'auto', marginTop: 10}}>
                <div style={{width: 150, fontSize: 12, fontWeight: 500}}></div>
                <div style={{width: 400, fontSize: 12, fontWeight: 700}}><span>Opening Balance</span></div>
                <div style={{width: 150, fontSize: 12, fontWeight: 500}}></div>
                <div style={{width: 200, fontSize: 12, fontWeight: 500}}></div>
                <div style={{width: 100, fontSize: 12, fontWeight: 700, textAlign: 'right'}}><span>{debitopeningbalance}</span></div>
                <div style={{width: 100, fontSize: 12, fontWeight: 700, textAlign: 'right'}}><span>{creditopeningbalance}</span></div>
                <div style={{width: 150, fontSize: 12, fontWeight: 700, textAlign: 'right'}}><span></span></div>
                <div style={{width: 70, fontSize: 14, fontWeight: 'bold', textAlign: 'right'}}><span></span></div>
              </div>
              <hr style={{marginTop: 2}}></hr>
              <div style={{maxHeight: 280, minHeight: 280, overflow: 'auto', marginTop: 10}}>
              {source.map((row) => {
                  return (
                    <div style={{display: 'flex', width: '100%', overflowX: 'auto', marginTop: 10}}>
                      <div style={{width: 150, fontSize: 12}}><span>{row.cb_date}</span></div>
                      <div style={{width: 400, fontSize: 12}}><span>{row.particulars}</span></div>
                      <div style={{width: 150, fontSize: 12, textAlign: 'right'}}><span>{row.cb_voucher_no_fk}</span></div>
                      <div style={{width: 200, fontSize: 12, textAlign: 'center'}}><span>{row.tran_name}</span></div>
                      <div style={{width: 100, fontSize: 12, textAlign: 'right'}}><span>{row.cb_debit}</span></div>
                      <div style={{width: 100, fontSize: 12, textAlign: 'right'}}><span>{row.cb_credit}</span></div>
                      <div style={{width: 150, fontSize: 12, textAlign: 'right'}}><span>{row.balance}</span></div>
                      <div style={{width: 70, fontSize: 12, textAlign: 'right'}}><span>{row.balanceside}</span></div>
                    </div>
                  )
                })}
              </div>
              <hr style={{marginBottom: -8}}></hr>
              <div style={{display: 'flex', width: '100%', overflowX: 'auto', marginTop: 10}}>
                <div style={{width: 150, fontSize: 12, fontWeight: 500}}></div>
                <div style={{width: 400, fontSize: 12, fontWeight: 700}}><span>Current Total</span></div>
                <div style={{width: 150, fontSize: 12, fontWeight: 500}}></div>
                <div style={{width: 200, fontSize: 12, fontWeight: 500}}></div>
                <div style={{width: 100, fontSize: 12, fontWeight: 700, textAlign: 'right'}}><span>{debitcurrenttotal}</span></div>
                <div style={{width: 100, fontSize: 12, fontWeight: 700, textAlign: 'right'}}><span>{creditcurrenttotal}</span></div>
                <div style={{width: 150, fontSize: 12, fontWeight: 700, textAlign: 'right'}}><span></span></div>
                <div style={{width: 70, fontSize: 14, fontWeight: 'bold', textAlign: 'right'}}><span></span></div>
              </div>
              <hr style={{marginTop: 2, marginBottom : -8}}></hr>
              <div style={{display: 'flex', width: '100%', overflowX: 'auto', marginTop: 10}}>
                <div style={{width: 150, fontSize: 12, fontWeight: 500}}></div>
                <div style={{width: 400, fontSize: 12, fontWeight: 700}}><span>Closing Balance</span></div>
                <div style={{width: 150, fontSize: 12, fontWeight: 500}}></div>
                <div style={{width: 200, fontSize: 12, fontWeight: 500}}></div>
                <div style={{width: 100, fontSize: 12, fontWeight: 700, textAlign: 'right'}}><span>{debitclosingbalance}</span></div>
                <div style={{width: 100, fontSize: 12, fontWeight: 700, textAlign: 'right'}}><span>{creditclosingbalance} </span></div>
                <div style={{width: 150, fontSize: 12, fontWeight: 700, textAlign: 'right'}}><span></span></div>
                <div style={{width: 70, fontSize: 14, fontWeight: 'bold', textAlign: 'right'}}><span></span></div>
              </div>
              <hr style={{marginTop:3}}></hr>
              <hr style={{marginTop: -14}}></hr>
            </div>
          </Paper> 
          <Modal
              size="md"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                  <Col xs={12}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Ledger Name</InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  // value={route}
                                  label="Age"
                                  onChange={ledgerNameShow}
                                  size="small"
                                  
                                >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {ledger.map((item, index) => (
                                    <MenuItem value={item.ledger_id}>{item.ledger_name}</MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                    </Col>
                    <Col xs={12} lg={6} style={{marginTop: 25}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="From Date"
                                value={fromDate}
                                onChange={(newValue) => {
                                  setFromdate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} lg={6} style={{marginTop: 25}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To Date"
                                value={toDate}
                                onChange={(newValue) => {
                                  setTodate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={() => ledgerFilter()} style={{float: 'right'}} variant="contained">Filter</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
        
      </div>
    )
}
export default LedgerdetailsMetro;