import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

const SidebarLink = styled(Link)`
display: flex;
color: #e1e9fc;
justify-content: space-between;
align-items: center;
padding: 20px;
list-style: none;
height: 60px;
text-decoration: none;
font-size: 20px;

&:hover {
	background: #efefef;
	border-left: 6px solid #7e77fe;
	border-right: 6px solid #7e77fe;
	cursor: pointer;
	color: #7e77fe;
}
`;

const SidebarLabel = styled.span`
margin-left: 16px;
&:hover {
	color: #7e77fe;
}
`;

const DropdownLink = styled(Link)`
height: 40px;
padding-left: 2.5rem;
display: flex;
align-items: center;
text-decoration: none;
color: #f5f5f5;
font-size: 18px;

&:hover {
	background: #fff;
	cursor: pointer;
	color: '#7e77fe'
}
`;

const DropdownLink1 = styled(Link)`
height: 40px;
padding-left: 4rem;
display: flex;
align-items: center;
text-decoration: none;
color: #f5f5f5;
font-size: 16px;

&:hover {
	background: #fff;
	cursor: pointer;
	color: '#7e77fe'
}
`;

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
	  width,
	  height
	};
  }

const SubMenu = (props) => {
	const [subnav, setSubnav] = useState(false);
	const [subsubnav, setSubsubnav] = useState(false);
	const [selectedIndex, setSelectedindex] = useState("");
	const location = useLocation();
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
	const [userId, setUserid] = React.useState("");

	const showSubnav = () => {
		setSubnav(!subnav)
		
	};

	const showSubsubnav = (index) => {
		setSelectedindex(index)
		setSubsubnav(!subsubnav)
		
	};

	const linkClick = () => {
		if(windowDimensions.width < 600) {
			props.parent(false)
		}
	}

	useEffect(() => {
		var userdata = JSON.parse(localStorage.getItem("user"))
		var branch = JSON.parse(localStorage.getItem("branch"))
		var company = JSON.parse(localStorage.getItem("company"))
		setUsercompanyid(company)
		setUserbranchid(branch)
		// setUsertype(userdata.user_type)
		setUserid(userdata.user_id)

		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
		
	}, []);

	return (
		<>
		{
			<SidebarLink className={props.item.path == location.pathname ? "activeItem hoveritem" : "hoveritem"} to={props.item.path == "" ? location.pathname : props.item.path}
				onClick={props.item.subNav && showSubnav}>
					<div>
					{props.item.icon}
					<SidebarLabel>{props.item.title}</SidebarLabel>
					</div>
					<div>
					{props.item.subNav && subnav
						? props.item.iconOpened
						: props.item.subNav
						? props.item.iconClosed
						: null}
					</div>
			</SidebarLink>
		}
		{subnav &&
			props.item.subNav.map((item, index) => {
			return (
				<div style={{paddingRight: 16}}>
					<DropdownLink style={{display: 'flex', justifyContent: "space-between"}} onClick={() => item.subSubNav && showSubsubnav(index)} className={item.path == location.pathname ? "subactiveItem hoveritem" : "hoveritem"} to={item.path == "" ? location.pathname : item.path}>
						<div>
							{item.icon}
							<SidebarLabel>{item.title}</SidebarLabel>
						</div>
						<div>
							{item.subSubNav  && subsubnav && selectedIndex == index 
								? item.iconOpened
								: item.subSubNav
								? item.iconClosed
							: null}
						</div>
					</DropdownLink>
						{
							subsubnav && selectedIndex == index && item.subSubNav && item.subSubNav.map((i, k) => {
									return(
										<DropdownLink1 onClick={() => linkClick(false)} className={i.path == location.pathname ? "subactiveItem hoveritem" : "hoveritem"} to={i.path} key={index}>
											{i.icon}
											<SidebarLabel>{i.title}</SidebarLabel>
										</DropdownLink1>
									)
								})
						}
				</div>
			);
			})}
			
		</>
	);
};

export default SubMenu;
