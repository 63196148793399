
import React, {Component, useEffect, useState, useRef} from 'react';
import { Row, Col, Form  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFormGroupUtilityClass } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Map from '../../google-map/map';
import { MultiSelect } from "react-multi-select-component";
import Input from '@mui/material/Input';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BtnLoader from '../../loader/btnLoader';
import Loader from '../../loader/loader';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";




function Fetchpage(props) {
    const inputRef = useRef();
    const resultsRef = useRef();

    useEffect(() => {
        if (props.hasResults) {
          document.body.addEventListener('keydown', onKeyDown);
        } else {
          document.body.removeEventListener('keydown', onKeyDown);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown)
        }
      }, [props.hasResults]);

    function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }
   
  return (
        <div>
            <Row>
                <Col sm={4} >
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="productcode"
                        onChange={props.updateform}
                        value={props.form.productcode} autoComplete="off" disabled id="outlined-basic" label="Product Combo Code" variant="outlined"  size="small"/>
                    </FormControl>


                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="refcode"
                        onChange={props.updateform}
                        value={props.form.refcode} autoComplete="off" id="outlined-basic" label="Reference code" variant="outlined"  size="small"/>
                    </FormControl>


                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="productname"
                        onChange={props.updateform}
                        value={props.form.productname} autoComplete="off" id="outlined-basic" label="Product Combo name" variant="outlined"  size="small"/>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="othername"
                        onChange={props.updateform}
                        value={props.form.othername} autoComplete="off" id="outlined-basic" label="Local Name" variant="outlined"  size="small"/>
                    </FormControl>

                    
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="printname"
                        onChange={props.updateform}
                        value={props.form.printname} autoComplete="off" id="outlined-basic" label="Print name" variant="outlined"  size="small"/>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="warranty"
                        onChange={props.updateform}
                        value={props.form.warranty} autoComplete="off" id="outlined-basic" label="Warranty" variant="outlined"  size="small"/>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="comboprice"
                        onChange={props.updateform}
                        value={props.form.comboprice} autoComplete="off" id="outlined-basic" type="number" label="Combo price" variant="outlined"  size="small"/>
                    </FormControl>

                  </Col>
                  <Col sm={4}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="description"
                        onChange={props.updateform}
                        value={props.form.description} autoComplete="off" id="outlined-basic" label="Description" variant="outlined"  size="small"/>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">HSN</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.hsn}
                                label="Age"
                                onChange={props.hsnChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.hsnList.map((item, index) => (
                                  <MenuItem value={item.hsn_id}>{item.hsn_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">TAX</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.tax}
                                label="Age"
                                onChange={props.taxChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.taxList.map((item, index) => (
                                  <MenuItem value={item.taxmaster_id}>{item.taxmaster_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.itemGroup}
                                label="Age"
                                onChange={props.itemgroupChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.itemgroupList.map((item, index) => (
                                  <MenuItem value={item.itemgroup_id}>{item.itemgroup_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Department</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.brand}
                                label="Age"
                                onChange={props.brandChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.brandList.map((item, index) => (
                                  <MenuItem value={item.brand_id}>{item.brand_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    

                    <Col xs={12} style={{marginTop: 20}}>
                          {/* <pre>{JSON.stringify(selected)}</pre> */}
                          <p>Branches</p>
                            <MultiSelect
                              options={props.branchlist}
                              value={props.selected}
                              onChange={props.setSelected}
                              labelledBy="Select"
                            />
                    </Col>
                    {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="stock"
                        onChange={props.updateform}
                        value={props.form.stock} type="number" autoComplete="off" id="outlined-basic" label="Combo stock" variant="outlined"  size="small"/>
                    </FormControl> */}
                  
                </Col>
                <Col sm={4}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.category}
                                label="Age"
                                onChange={props.categoryChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.categoryList.map((item, index) => (
                                  <MenuItem value={item.category_id}>{item.category_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Sub Categeory</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.subCategory}
                                label="Age"
                                onChange={props.subcategoryChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.subcatlist.map((item, index) => (
                                  <MenuItem value={item.subCategory_id}>{item.subCategory_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Base unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.baseUnit}
                                label="Age"
                                onChange={props.baseunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Purchase unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.purchaseUnit}
                                label="Age"
                                onChange={props.purchaseunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Sale unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.salesUnit}
                                label="Age"
                                onChange={props.salesunitChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.unitList.map((item, index) => (
                                  <MenuItem value={item.unit_id}>{item.unit_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Price level</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.defaultPrice}
                                label="Age"
                                onChange={props.defaultpriceChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.priceList.map((item, index) => (
                                  <MenuItem value={item.pricelevel_id}>{item.pricelevel_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                </Col>
            </Row>

            <Row >
                <Col sm={5} className="productbatchborder">
                    <label style={{fontWeight: 'bold'}}>Combo Products</label>
                    {
                    props.combolistloader ? 
                    <Loader /> :
                    props.tableRow.map((item, index) => {
                            return (
                                <Row style={{marginTop: 10}}>
                                    <Col sm={3}>
                                    <input style={{width: '100%'}} placeholder="Reference code" autocomplete="off" ref={inputRef} type="text" name="query" value={item.product_code} onChange={(event) => props.handleOnChange(event, index, "code")} />
                                        {props.hasResults && props.selectedIndex == index && (
                                            <div className="autocomplete">
                                                <ul ref={resultsRef} className="people scrolling-div">
                                                    {props.productlist.map(item => {
                                                    return (
                                                      
                                                        <li key={item.url}>
                                                            <a href="javascript:void(0)" onClick={() => props.listClickcode(
                                                                index, 
                                                                item.products_id,
                                                                item.product_code,
                                                                item.product_name,
                                                                item.product_refcode
                                                                )}>
                                                                {item.product_refcode} - { item.product_name }
                                                            </a>
                                                        </li>
                                                     ) })}
                                                </ul>
                                            </div>
                                        )}
                                    </Col>
                                    <Col sm={4}>
                                        <input style={{width: '100%'}} placeholder="Qty" type="number" autocomplete="off" ref={inputRef} type="text" name="query" value={item.product_quantity} onChange={(event) => props.stockChange(event, index, "code")} />
                                    </Col>
                                    <Col sm={3}>
                                        <label style={{fontSize: 10,}} >{item.product_name}</label>
                                    </Col>
                                    <Col sm={2}>
                                        <a href="javascript:void(0)" onClick={() => props.deleteCombo(index)}>
                                            <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                        </a> 
                                    </Col>
                                    
                                </Row>
                            )
                            })
                        }
                        <Row style={{marginTop:10}}>
                            <Col sm={2}>
                                    <Button onClick={props.addtablerow} style={{float: 'right', marginBottom: 20}}  variant="contained">Add</Button>
                              </Col>
                        </Row>
                </Col>
                <Col sm={3} style={{marginTop: 30}}>
                               
                </Col>
            </Row>
        </div>
  );
}

export default Fetchpage;
