import React, { useState, useEffect } from "react";
import '../Onboard/Onboard.css';
import {useHistory} from 'react-router-dom';
import {Container, Row, Col, Button, Form} from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     Redirect,
//   } from "react-router-dom";
import Box from '@mui/material/Box';
import {BsArrowReturnRight} from "react-icons/bs"
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';


function Onboard() {
    const [source, setSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [enteredword, setEnteredWord] = useState("");
    let history = useHistory();


    useEffect(() => {
        setLoading(true)
        fetch(global.url + "viewAllCompany", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log('responseJson', responseJson)
            setLoading(false)
            setSource(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
	}, []);

    const searchCompany = (event) => {
        const searchWord = event.target.value;
        console.log('searchWord', searchWord)
        setEnteredWord(searchWord);
        var data = {
            "search_company":searchWord
        
        }
        fetch(global.url + "companySearch",{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129',
                
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          
            console.log("search response", responseJson)
            if(responseJson.error == false){
                setSource(responseJson.data)
            } else {
                setSource([])
            }
        })
        .catch((error) => {
            console.log(error)
        })
      }
   
    return(
        <div className="App">
            <header className="onboard-component">
                <Container fluid>
                    <Row>
                        <Col xs={12} style={{marginTop: 20}}>
                            {/* <div style={{textDecoration: "unset", cursor: 'pointer'}}>
                                <a href={'/loginsuperadmin'} style={{color:'unset', textDecoration: 'none'}}>
                                    <div className="onborad-box">
                                        <div className="onboardbox-inside">
                                            <h1 className="onboardbox-head">NIGC</h1>
                                            <p className="onboardbox-para">Nas International Group of Companies</p>
                                            <p className="onboardbox-para" style={{fontSize: 20}}>Super Admin</p>
                                        </div>
                                    </div>
                                </a>
                            </div> */}
                            <Container style={{marginTop: 50}}>
                                <p className="onboard-companies">OUR COMPANIES</p>
                                {/* <Row>
                                    <Col md={4}>
                                        <div style={{backgroundColor: '#fff', width: '100%', height: 45, borderRadius: 5 }}>
                                            <FormControl style={{marginTop: 5}} sx={{width: '100%'}}>
                                                <TextField  id="outlined-basic" variant="outlined"  size="small" label="Search Company"
                                                onChange={searchCompany} value={enteredword} 
                                                />
                                                
                                            </FormControl>
                                        </div>
                                        
                                    </Col>
                                </Row> */}
                                {loading == false  ?
                                <Row>
                                    {/* <Col xs={12} lg={6}>
                                        <a href={'/loginsuperadmin'} style={{color:'unset', textDecoration: 'none'}}>
                                        <div className="second-box">
                                            <Row>
                                              
                                                <Col xs={12} sm={10}>
                                                    <p className="box-para1">Metrofood</p>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <p className="box-para3">Super Admin</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        </a>
                                    </Col> */}
                                    {/* {source.map((item, index) => (
                                        <Col xs={12} lg={6}>
                                        <a href={'/login/' + item.company_id} style={{color:'unset', textDecoration: 'none'}}>
                                        <div className="second-box">
                                            <Row>
                                                <Col xs={12} sm={2}>
                                                    <img alt="ss" src={global.images + "company_logo/" + item.company_logo} style={{width: 50, marginTop: 40}} />
                                                </Col>
                                                <Col xs={12} sm={10}>
                                                    <p className="box-para1">{item.company_name}</p>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <p className="box-para3"><FaPhoneAlt color="#000" size={15} style={{marginRight: 5}}/>Contact No.</p>
                                                            <p className="box-para3"><MdLocationOn color="#000" size={22} style={{marginRight: 1, marginTop:-3}}/>Location</p>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <p className="box-para3">{item.company_mobile2}</p>
                                                            <p className="box-para3">{item.company_address1}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        </a>
                                    </Col>
                                    ))} */}

                                     {source.map((item, index) => (
                                        <Col xs={12} lg={6}>
                                        <a onClick={() => history.push('/onboard' )} style={{color:'unset', textDecoration: 'none'}}>
                                        <div className="second-box">
                                            <Row>
                                                <Col xs={12} sm={2}>
                                                    <img alt="ss" src={global.images + "company_logo/"} style={{width: 50, marginTop: 40}} />
                                                </Col>
                                                <Col xs={12} sm={10}>
                                                    <p className="box-para1">{item.company_name}</p>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <p className="box-para3"><FaPhoneAlt color="#000" size={15} style={{marginRight: 5}}/>Contact No.</p>
                                                            <p className="box-para3"><MdLocationOn color="#000" size={22} style={{marginRight: 1, marginTop:-3}}/>Location</p>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <p className="box-para3">{item.company_mobile2}</p>
                                                            <p className="box-para3">{item.company_address1}</p>
                                                        </Col>
                                                        
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        </a>
                                    </Col>
                                    ))}
                                    
                                    <Col xs={12} lg={6}>
                                        <div className="second-box2">
                                            <p className="comingsoon">COMING SOON</p>
                                        </div>
                                    </Col>
                                </Row>:

                                <Row>
                                    <Col sm={6}>
                                        <Box sx={{ width: '100%' }}>
                                            <Skeleton animation="wave" style={{height: 250}}>
                                                    
                                            </Skeleton>
                                           
                                        </Box>
                                    </Col>
                                    <Col sm={6}>
                                        <Box sx={{ width: '100%' }} >
                                            <Skeleton animation="wave" style={{height: 250}}>
                                                    
                                            </Skeleton>
                                           
                                        </Box>
                                    </Col>
                                </Row>
                                }
                            </Container>
                        </Col>
                    </Row>
                    
                </Container> 
            </header>
        </div>
    )
}

export default Onboard;