import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader';

const columns = [
    { id: '1', label: 'Sub Group Name', minWidth: 50,span: false },
    { id: '2', label: 'Group Code', minWidth: 50,span: false },
    { id: '3', label: 'Main Group', minWidth: 50,span: false },
    // { id: '4', label: 'Blocked', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
    },

  ];

function State(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [toggle,  setToggle] = useState(false)
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [groupId, setGroupid] = React.useState("");
    const [groupName, setGroupname] = React.useState("");
    const [grpmainId, setGrpmainid] = React.useState("");
    const [blocked, setBlocked] = React.useState(0);
    const [groupUserid, setGroupUserid] = React.useState("");
    const [grpCode, setGroupcode] = React.useState("");
    const [grpCandelete, setGroupcandelete] = React.useState(0);
    const [grpType, setGrptype] = React.useState("")
    const [mainGrouplist, setMaingrouplist] = React.useState([])
    

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setGroupcandelete(1)
    }
    else {
      setGroupcandelete(0)
    }
  }

  const blockChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  useEffect(() => {
    getData()
    getMaingroup()
  }, []);

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setGroupUserid(userdata.user_id)

    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewGroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response123", responseJson)
        if(responseJson.status == 'false'){
          setLoader(false)
          setSource(responseJson.data)
        }
        else{
          setLoader(false)
        }
        
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    const editClick = (
        groupid,
        groupuserid, 
        grpname,
        grpcode,
        grpmaingrp,
        grptype,
        blocked,
        grpcandelete,      
      ) => {
        setModal(true)
        setGroupid(groupid)
        setGroupname(grpname)
        setGroupcode(grpcode)
        setGrpmainid(grpmaingrp)
        setBlocked(blocked)
        setGroupcandelete(grpcandelete)
        setGrptype(grptype)
        getMaingroup()
    }

    const onAdd = () => {
        setModal(true)
        setGroupid("")
        setGroupname("")
        setGrpmainid("")
        setBlocked(0)
        setGroupcode("")
        setGroupcandelete(0)
        setGrptype()
        
  }

    const getMaingroup = () => {
      fetch(global.url + "viewMaingroup", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify()
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("maingroup", responseJson)
        setMaingrouplist(responseJson.data)
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const mainGroupchange = (event) => {
      setGrpmainid(event.target.value);
    };

    const grouptypeChange = (event) => {
      setGrptype(event.target.value);
    };

    const onSave = () => {
      var data = {
        "group_userid": groupUserid,
        "group_name": groupName,
        "group_code": grpCode,
        "group_maingroup":  grpmainId,
        "group_type": grpType,
        "group_blocked": blocked,
        "group_candelete": grpCandelete,
        "groupid" : groupId,
        "group_branchid" : userbranchid,
        "group_companyid" : usercompanyid
      }
      console.log("data save", data)
      fetch(global.url + "updateGroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            getData()
            setModal(false)
            alert(responseJson.message)
          }
          if(responseJson.status == "true") {
            alert(responseJson.message)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const deleteClick = (id) =>  {
      var data = {
        "groupid": id,
      }
      fetch(global.url + "deleteGroup", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("del", responseJson)
          alert("Deleted")
          getData()
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sub Group List</p>
               {
                    usertype == "superadmin" ? 
                    null : 
                    <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.group_name}
                              </TableCell>
                              <TableCell >
                                  {row.group_code}
                              </TableCell>
                              <TableCell >
                                  {row.mainGroup_name}
                              </TableCell>
                              {/* <TableCell >
                                  {row.group_blocked  == "0" ? "No" : "Yes"}
                              </TableCell> */}
                              <TableCell >
                              {
                                    usertype == "superadmin" ? 
                                    null : 
                                    <a href="javascript:void(0)" onClick={() => 
                                        editClick(
                                          row.group_id,
                                          row.group_userid, 
                                          row.group_name, 
                                          row.group_code,
                                          row.group_maingroup,
                                          row.group_type,
                                          row.group_blocked,
                                          row.group_candelete,
                                        )}>
                                          <FaIcons.FaEdit fontSize={20} color="blue" />
                                        </a>
                                }
                              </TableCell>
                              { row.group_candelete == "0" ? null :
                                <TableCell >
                                    <a href="javascript:void(0)" onClick={() => deleteClick(row.group_id)}>
                                        <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                    </a>
                                </TableCell>
                               }
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Group Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={groupName} onChange={(e) => setGroupname(e.target.value)} id="outlined-basic" label="Group Name" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={grpCode} onChange={(e) => setGroupcode(e.target.value)} id="outlined-basic" label="Group Code" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Main Group</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={grpmainId}
                                label="Age"
                                onChange={mainGroupchange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {mainGrouplist.map((item, index) => (
                                  <MenuItem value={item.mainGroup_id}>{item.mainGroup_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                      <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Group Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={grpType}
                                label="Age"
                                onChange={grouptypeChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Cash">Cash</MenuItem>
                              <MenuItem value="Bank">Bank</MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                              
                            </Select>
                      </FormControl>
                      <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    {/* <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={blocked == 0? false : true} onChange={e => blockChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={grpCandelete == 0? false : true} onChange={e => deleteChange(e)} />
                            } label="Can Delete" />
                        </FormControl>
                    </Col> */}
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default State;