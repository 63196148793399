import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Modal  } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill } from "react-icons/bs";
import { FaArrowCircleRight } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import Loader from '../loader/loader';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import './login.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function LoginMetro() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [branch, setBranch] = useState("");
    let history = useHistory();
    const { id } = useParams();
    const [loader, setLoader] = useState(false);

    const [emailmodal, setEmailmodal] = useState(false);

    const [email, setEmail] = useState("");

    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrormessage] = React.useState(false);

    const vertical = "top"
    const horizontal = "center"

    const onSubmit = () => {
        setLoader(true)
        var data = {
            "user_name": username,
            "user_password": password,
        }
        fetch(global.url + "Metrosuperlogin", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
            if(responseJson.error == true) {
                // alert(responseJson.message)
                setOpen(true)
                setErrormessage(responseJson.message)
            }
            else{
                localStorage.setItem("isAuthenticated", true);
                localStorage.setItem("user", JSON.stringify(responseJson.data))
                history.push('/master/company/metro')
            }
             console.log("login Response", responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    const forgotClick = () => {
        setEmailmodal(true)
        setEmail("")
    }

    const forgotSubmit = () => {
        setLoader(true)
        var data = {
            "emailid": email,
        }
        fetch(global.url + "forgotPassword", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
            
            if(responseJson.error == false){
               alert("New Password sent to the Email account")
               setEmailmodal(false)
               setEmail("")
            }
            else{
                alert(responseJson.message)
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }



  return (
    <div className="App">
      <header className="Login-component">
            <Container fluid>
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                        <div className="login-box">
                            <div className="box-inside">
                                <div className="login-title">
                                    <strong>NASSCRIPT</strong>
                                </div>
                                <div class="frm-title">
                                    Welcome back! Please login to continue
                                </div>
                                <Form>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField className="login-textfield" value={username} onChange={(e) => setUsername(e.target.value)} id="outlined-basic" label="Username" variant="outlined"  size="small"/>
                                        <BsPersonFill color="#999"  style={{position: 'absolute', top:0, left: 0, marginTop: 10, marginLeft: 10}}/>
                                    </FormControl>

                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField className="login-textfield" value={password} onChange={(e) => setPassword(e.target.value)} id="outlined-basic" label="Password" variant="outlined" type="password"  size="small"/>
                                        <BsFillLockFill color="#999"  style={{position: 'absolute', top:0, left: 0, marginTop: 10, marginLeft: 10}}/>
                                    </FormControl>

                                    <div >
                                        <div style={{paddingBottom: 20}}>
                                            {loader ? <Loader /> :
                                                <a href="javascript:void(0)" onClick={() => {
                                                        onSubmit()
                                                    }}  variant="primary" type="submit" style={{marginTop: 20, background: '#8a4cff', borderColor: '#8a4cff', color: '#fff', textDecoration: 'none', width: 100, padding: 5, borderRadius: 5}}>
                                                    Login
                                                    <FaArrowCircleRight color="#fff" size={12} className="lefticon"/>
                                                </a>
                                            }
                                        </div>
                                        <a href="javascript:void(0)" onClick={() => forgotClick()} style={{textDecoration: "unset"}}>Forgot Password</a>
                                    </div>
                                </Form>
                                <div class="frm-footer"><span className="nastext">NASScript</span> © 2021.</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            
           <Modal
              size="sm"
              show={emailmodal}
              onHide={() => setEmailmodal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton >
                <Modal.Title id="example-modal-sizes-title-lg">
                    <p style={{fontSize: 18, marginBottom: 'unset', marginTop: 'unset'}}>Forgot Password</p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                            <TextField  value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                        </FormControl>
                        {loader ? <Loader /> :
                        <Button onClick={() => forgotSubmit()} variant="contained" style={{marginTop: 20}}>Submit</Button>
                        }
                  </div>
              </Modal.Body>
           </Modal>
      </header>
      <Snackbar anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default LoginMetro;
