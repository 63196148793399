
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Map from '../../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';





function WizardFormSecondPage(props) {
    const [value, setvalue] = React.useState('');
    
console.log("tablerow", props.tableRow)
  return (
        <div>
            <Row>
                <Col xs={12} >
                <div>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Date
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Bill No
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Payable
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Recievable
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}} colSpan={2}>
                                    Actions
                                </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {props.tableRow
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.key}>
                                        <TableCell>
                                            {row.lob_date}
                                        </TableCell>
                                        <TableCell >
                                            {row.lob_refr_no}
                                        </TableCell>
                                        <TableCell >
                                            {row.lob_payable}
                                        </TableCell>
                                        <TableCell >
                                            {row.lob_receivable}
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={() => props.deleteBill(row.lob_id)}>
                                                <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                            <TableRow>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Total:
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                    <Row>
                        <Col xs={12} md={3} style={{marginTop: 30}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date"
                                    value={props.date}
                                    onChange={(newValue) => {
                                    props.setDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl >
                                <TextField  value={props.billNo} onChange={(e) => props.setBillno(e.target.value)} id="outlined-basic" label="Bill No" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl >
                                <TextField  value={props.payable} onChange={(e) => props.setPayable(e.target.value)} id="outlined-basic" label="Payable" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl >
                                <TextField  value={props.recievable} onChange={(e) => props.setRecievable(e.target.value)} id="outlined-basic" label="Recievable" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={2} xs={12} style={{marginTop: 30}}>
                            <Button onClick={props.addRow} style={{float: 'right'}} variant="contained">Add</Button>
                        </Col>
                    </Row>
                </div>
                </Col>
            </Row>
        </div>
  );
}

export default WizardFormSecondPage;
