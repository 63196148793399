import React, {useEffect, useState} from 'react'
import Paper from '@mui/material/Paper';
import Table from 'react-bootstrap/Table';
import {Container, Row, Col, Form, Button} from "react-bootstrap"
import baladna from '../../assets/images/baladna11.png'
import { useHistory, useParams } from "react-router-dom";

function Employee1() {

  const [details, setDetails] = React.useState([])
  const [user, setUsers] = React.useState()


  useEffect(() => {
    getdata()
    
  }, []);

 
  const getdata = () => {
   
    var data = {
      "company_id" : 25,
    }
    fetch(global.url + "listofusers", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((responseJson) => {
        console.log("change", responseJson)
        setDetails(responseJson.data)
        
    })
    .catch((error) => {
        console.log(error)
    })
}
   
const Active = (did) => {
  var userdata = JSON.parse(localStorage.getItem("user"))
  var data = {
    "type": "superadmin",
    "value": 1,
    "user_id": did 
  }
  console.log("data", data)
  fetch(global.url + "statusassignadmin", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  .then((response) => response.json())
  .then((responseJson) => {
      console.log("response", responseJson)
     
      getdata()
  })
  .catch((error) => {
      console.log(error)
  })
}
 
const Deactive = (did) => {
  var userdata = JSON.parse(localStorage.getItem("user"))
  var data = {
    "type": "superadmin",
    "value": 0,
    "user_id": did 
  }
  console.log("data", data)
  fetch(global.url + "statusassignadmin", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  .then((response) => response.json())
  .then((responseJson) => {
      console.log("response", responseJson)
     
      getdata()
  })
  .catch((error) => {
      console.log(error)
  })
}
 
return (
  <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Container>
          <Row style={{marginTop:40}}>
              <Col xs={12} md={12} >
              <div style={{fontSize:25, fontWeight:'bold', textAlign:'center'}}>Employees</div>
              
       <Table style={{marginTop:40}} striped bordered hover responsive>
          <thead>
          <tr>
          <th></th>
          <th>User ID</th>
          <th>Name</th>
          <th>Place</th>
          <th>Phone No</th>
          <th>Status</th>
          </tr>
          </thead>
          <tbody>
          {
          details.map((row, index) => {
              return(
              <tr>
              <td>{index+1}</td>
              <td>{row.user_id}</td>
              <td>{row.user_name}</td>
              <td>{row.user_location}</td>
               <td>{row.user_phone}</td>
               <td>
               {row.user_status == 0 ?
                <Button onClick={() => Active(row.user_id)} variant="primary" style={{height: 25, paddingTop: 2, fontSize: 13, border: 'none', boxShadow: 'none'}}>Activate</Button>:
              
                <Button onClick={() => Deactive(row.user_id)} variant="primary" style={{height: 25, paddingTop: 2, fontSize: 13, border: 'none', boxShadow: 'none'}}>Deactive</Button>}
                </td>
              </tr> 
               )
                                               
              })
          }
          </tbody>
      </Table>
      </Col>
          </Row>
          </Container>
          </Paper>
  </div>
)
}

export default Employee1