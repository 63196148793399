
import React, {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';


const dates = [
    {
      company: 'Lorem Ipsum'
    },
    {
      company: 'Lorem Ipsum'
    }
]

function WizardFormSecondPage(props) {
    const [age, setAge] = React.useState('');
    const [value, setValue] = React.useState('1');
    const [date, setDate] = React.useState(null);
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [userId, setUserid] = React.useState("");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  
  return (
    <div>
    <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow>
                <TableCell style={{fontWeight: 'bold'}}>
                    Code
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                    Branch
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                        
                </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {props.branch
                .map((row, index) => {
                return (
                    <TableRow  tabIndex={-1} key={row.code}>
                        <TableCell>
                            {row.branch_code}
                        </TableCell>
                        <TableCell >
                            {row.branch_name}
                        </TableCell>
                        <TableCell >
                                {/* <FormControl sx={{ width: '100%' }}>
                                    <FormControlLabel control={
                                    <Checkbox checked={props.checked[index]}  />
                                    } label="Allow" 
                                    onChange= {e =>props.checkChange1(e, index, row.branch_id, row.branch_name)}
                                    />
                                </FormControl> */}
                        </TableCell>
                    </TableRow>
                );
                })}
            </TableBody>
        </Table>
    </TableContainer>
</div>
  );
}

export default WizardFormSecondPage;
