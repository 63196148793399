import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../google-map/map';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader'

const columns = [
    { id: '1', label: 'Location Name', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];



function Location(props) {
 
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [source, setSource] = React.useState([]);
  const [usercompanyid, setUsercompanyid] = useState("")
  
  const onAdd = () => {
    setModal(true)
  }
 
  useEffect(() => {
    getData()
     }, []);

     const getData = () => {
        var superadmin = JSON.parse(localStorage.getItem("superadmin"))
        setUsercompanyid(superadmin)
        
        var data ={
            "type": superadmin,
        }

        fetch(global.url + "backendLocList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129',
            },
            body: JSON.stringify(data),

        })
        .then((response)=> response.json())
        .then((responseJson) => {
            console.log("search response", responseJson)
            setSource(responseJson.data)
        })
       
    }
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Location List</p>
               <Button variant="contained" onClick={onAdd}>Add</Button>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                  
                  .map((row) => {
                    <TableRow  tabIndex={-1}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                { row.location_name}
                              </TableCell>
                            
                              
                              <TableCell >
                                  <a href="javascript:void(0)" >
                                    <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                            
                        </TableRow>
                  })}
                        
                      
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Location Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    
                  <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" label="Location Name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button  style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Location;