import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Form, Button} from "react-bootstrap"
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import { useHistory, useParams } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Loader from '../loader/loader';
import { FaArrowCircleRight } from "react-icons/fa";
import { id } from 'date-fns/locale';

const Alert = React.forwardRef(function Alert(props, ref) {
return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Loginlimousine() {

    let history = useHistory();
    const vertical = "top"
    const horizontal = "center"
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
  
    const [usernameErr, setUsernameErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
  
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrormessage] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
  
    const onSubmit = (e) => {
     
      const isValid = formValidation();
  }
  const formValidation = (e) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const re = /^[0-9\b]+$/;
    const usernameErr = {};
    const passwordErr = {};
    let isValid = true;
  
    if(username.trim().length == 0){
        usernameErr.namerequired = 'Name is required'
    }
    if(password.trim().length == 0){
        passwordErr.passwordRequired = 'Password is required';
        isValid = false;
    }
    if(password.trim().length < 6){
        passwordErr.passwordRequired = 'Password is too short (Min 6 Letters)';
        isValid = false;
    }
    if(password.trim().length > 20){
        passwordErr.passwordRequired = 'Password is too long (Max 20 Letters)';
        isValid = false;
    }
    if(username.trim().length != 0 && password.trim().length !=0)  {
        getData()
    }
    setUsernameErr(usernameErr);
    setPasswordErr(passwordErr);
    return isValid  
  }
  const handleClose = (event, reason) => {
    if(reason === 'clickaway'){
        return;
    }
    setOpen(false);
  }
  
  const getData = () => {
    setLoader(true)
    var data = {
        "emailid" : username , 
        "password" : password,
       
    }
    console.log(data, "error")
    fetch(global.url + "driverLogin", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          'APPKEY': 'erp6129'     
      },
      body: JSON.stringify(data)
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("response login", responseJson)  
      setLoader(false)
      if(responseJson.error == false){
        localStorage.setItem("user", JSON.stringify(responseJson.data))
        
         history.push('/checkin');
      }else{
        setOpen(true)
        setErrormessage(responseJson.message)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
  }
    return (
        <div className="App">
        <header className="Login-component">
              <Container fluid>
                  <Row >
                      <Col xs={12} style={{marginTop: 20}}> 
                          <div className="login-box">
                              <div className="box-inside">
                                  <div className="login-title">
                                      <strong>NASSCRIPT
                                          
                                      </strong>
                                  </div>
                                  <div class="frm-title">
                                      Welcome back! Please login to continue web
                                  </div>

                                  <div style={{marginTop:20}}>
                                        <Form>
                                        <Form.Group className="mb-1" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                className='shadow12' 
                                                value={username}
                                                onChange={(e) => {setUsername(e.target.value)}} 
                                                placeholder='Enter Your Email' />
                                        </Form.Group>
                                        {Object.keys(usernameErr).map((key) => {
                                                            return <div style={{color: 'red', fontSize: 12}}>{usernameErr[key]}</div>
                                                        })}
                                    </Form>
                                        </div>
                                        
                                        <div style={{marginTop:20}}>
                                        <Form>
                                        <Form.Group className="mb-1" controlId="formBasicEmail">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control 
                                                type="password"
                                                className='shadow12' 
                                                value={password}
                                                onChange={(e) => {setPassword(e.target.value)}} 
                                                placeholder='Enter Your Password' />
                                        </Form.Group>
                                        {Object.keys(passwordErr).map((key) => {
                                                            return <div style={{color: 'red', fontSize: 12}}>{passwordErr[key]}</div>
                                                        })}
                                    </Form>
                                        </div>
                                  <Form>     
                                      <div >
                                          <div style={{paddingBottom: 20}}>
                                              {loader ? <Loader /> :
                                                  <a href="javascript:void(0)" onClick={() => {
                                                          onSubmit()
                                                      }}  variant="primary" type="submit" style={{marginTop: 20, background: '#8a4cff', borderColor: '#8a4cff', color: '#fff', textDecoration: 'none', width: 100, padding: 5, borderRadius: 5}}>
                                                      Login
                                                      <FaArrowCircleRight color="#fff" size={12} className="lefticon"/>
                                                  </a>
                                              }
                                          </div>
                                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                          <a  onClick={() => history.push('/signup' )} style={{textDecoration: "unset"}}>Signup</a>
                                          <a   style={{textDecoration: "unset"}}>Forgot Password</a>
                                          
                                          </div>
                                      </div>
                                  </Form>
                                  <div class="frm-footer"><span className="nastext">NASScript</span> © 2021.</div>
                              </div>
                          </div>
                      </Col>
                  </Row>
              </Container>
             
  
            
        </header>
        <Snackbar anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </div>
    )
  }




   
export default Loginlimousine;










