import { Container, Row, Col, Button, Form  } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import './footer.css'

function Footer() {
  return (
      <div className="footer-content">
          <div style={{background: '#efefef', width: '100%', padding: 20}}>
          <ul className="footer-list">
                <li>
                    User :Admin   NAS104817
                </li>
                <li style={{color: '#8a4cff'}}>
                    <a href="https://nasscript.com/" target="_blank" style={{textDecoration: 'none'}}>
                        NASScript Software Innovations
                    </a>
                </li>
                <li>
                    Name of financial year:2021
                </li>
                <li>
                    Financial Year Period 01-jan-2021 To 31-dec-2021
                </li>
                <li>
                    ©2021 NASScript Software Innovations W.L.L
                </li>
                <li style={{color: 'orangered'}}>
                    Branch: QATAR
                </li>
            </ul>
          </div>
    </div>
  );
}

export default Footer;
