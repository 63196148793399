import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Form, Button} from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
function Checkin() {

    let history = useHistory();

    const [name, setName] = React.useState("")
    const [uid, setUid] = React.useState("")
    const [mid, setMid] = React.useState("")
   
    useEffect(() => { 
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log("userdata1", userdata)
        setName(userdata.user_name)
        setUid(userdata.user_id)
        setMid(userdata.user_email)
      }, []);

  const onSubmit = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log("userdata", userdata)
      var data = {
          "company_id" : 25,
          "driver_id" :userdata.user_id,
      }

      console.log("data1", data)
      fetch(global.url + "buttonOnDriverPage", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
         console.log("responseJson", responseJson)
        
         history.push('/driver');
      })
      .catch((error)=>{
          console.log(error)
      })
  }




  return (
   

<div className="App">
<header className="Login-component">
      <Container fluid>
          <Row >
              <Col xs={12} style={{marginTop: 20}}> 
                  <div className="login-box">
                      <div className="box-inside">
                          <div className="login-title">
                              <strong>NASSCRIPT
                                  
                              </strong>
                          </div>
                          <div class="frm-title">
                              Welcome back! Please checkin to continue web
                          </div>
                                <div className="login-box">
                                    <div className="box-inside">
                                        <div style={{marginTop:20, display: 'flex', fontSize: 18}}>
                                            <div>User Id : </div>
                                            <div>{uid}</div>
                                        </div> 
                                        <div style={{marginTop:20, display: 'flex', fontSize: 18}}>
                                            <div>User Name : </div>
                                            <div>{name}</div>
                                        </div> 
                                        <div style={{marginTop:20, display: 'flex', fontSize: 18}}>
                                            <div>Mail Id : </div>
                                            <div>{mid}</div>
                                        </div>                           
                                    </div>
                                 </div>      

                            <Form>     
                              <div style={{marginTop:20}}>
                                  
                                  <Button onClick={() => onSubmit()} variant="primary" style={{height: 40, width: 120, paddingTop: 5, fontSize: 20, marginleft:140, border: 'none', boxShadow: 'none'}}>Checkin</Button>
                              </div>
                          </Form>
                          <div class="frm-footer"><span className="nastext">NASScript</span> © 2021.</div>
                      </div>
                  </div>
              </Col>
          </Row>
      </Container>
     

    
</header>

<div>
        <Container>
            <Row>
                <Col xs={12} md={4}>

                </Col>
                <Col xs={12} md={4}>
                    <div  style={{marginTop:350}}>
                <Button onClick={() => onSubmit()} variant="primary" style={{height: 40, width: 120, paddingTop: 5, fontSize: 20, marginleft:140, border: 'none', boxShadow: 'none'}}>Checkin</Button>
                </div>        
                </Col>
                <Col xs={12} md={4}>
                </Col>
            </Row>
        </Container>
    </div>
</div>
  )
}

export default Checkin