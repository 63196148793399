import React, {Component, useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import Sidebar from '../sidebar/sidebar';
import Footer from '../footer/footer';
import accessimgae from '../../assets/images/access.png'
import { FormName } from 'redux-form';

const DashboardLayoutLimo = ({ children, ...rest }) => {
    const [toggle,  setToggle] = useState(false)
    
    function setData(childData) {
      setToggle(childData)
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}} className={toggle == false ? "toggle": ""}>
            <Sidebar activeData = {toggle} getData = {setData} />
              <div style={{marginTop: '80px'}}>{children}</div>:
            <Footer />
        </div>
      );
    };
    
    const DashboardLayoutRouteLimo = ({ component: Component, ...rest }) => {
      const isAuthenticated = localStorage.getItem("isAuthenticated");
      return (
        <Route
          {...rest}
          render={(props) => (
            isAuthenticated ?  
            <DashboardLayoutLimo>
              <Component {...props} />
            </DashboardLayoutLimo> :
            <Redirect to="/" /> 
          )}
        />
      );
    };

export default DashboardLayoutRouteLimo