import React, { useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import { useHistory, useParams } from "react-router-dom";
import baladna from '../../assets/images/baladna11.png'
import {Container, Row, Col, Form, Button} from "react-bootstrap"

function Outlet() {

  let history = useHistory();
  const [details, setDetails] = React.useState([])
  const [driver, setDriver] = React.useState("")

  const logout = () => {
    history.push('/onboard')
    localStorage.removeItem("user")
}

  useEffect(() => {
   getdata()
}, []); 

const getdata =() => {
    
    var data = {
        "company_id" : 25,
    }
    
    fetch(global.url + "availableDrivers", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("details", responseJson)
       setDetails(responseJson.data)
       
    })
    .catch((error)=>{
        console.log(error)
    })
}

const activate = (id, did) => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    console.log("userdata", userdata)
    var data = {
      "company_id": 25 ,
      "user_id":did,
      "task_id": id,

      
    }
    console.log("data", data)
    fetch(global.url + "userAssigningTaskToDriver", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((responseJson) => {
        console.log("change password response", responseJson)
        setDriver(responseJson.data.ds_driver_id)
        getdata()
    })
    .catch((error) => {
        console.log(error)
    })
}


// const deactivate = () => {
//     var userdata = JSON.parse(localStorage.getItem("user"))
//     console.log("check")
//     var data = {
//       "company_id": 25 ,
//       "user_id": userdata.user_id
     
//     }
//     console.log("data", data)
//     fetch(global.url + "userAssigningTaskToDriver", {
//       method: "POST",
//       headers: {
//           'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     })
//     .then((response) => response.json())
//     .then((responseJson) => {
//         // console.log("change password response", responseJson)
//         getdata()
//     })
//     .catch((error) => {
//         console.log(error)
//     })
// }

  return (
    <div>
      
            <Row >
                <div style={{backgroundColor: "#8A1538"}}>
                <Col xs={12} md={12}>
                <div style={{display: "flex",justifyContent: "space-between"}}>
                  {/* <div>
                    <img src={baladna} style={{width: 170, height: 110}}/>
                  </div> */}
                   <Col xs={12} md={11}>
                     <div style={{fontSize:35, fontWeight:'bold', color: '#ffffff', textAlign: 'center', marginTop: 28, marginBottom:10}}>OUTLET SAFARIMALL <sapan style={{fontSize:20}}>(Abu hamoor)</sapan></div>
                     </Col> 
                     <Col xs={12} md={1}>   
                     <div style={{marginTop:35}} href="javascript:void(0)" onClick={logout}> 
                     <img src={require("../../assets/images/icons/logout.svg").default} style={{width: 45, height: 45, marginTop:1}} />                     
                     </div>
                     </Col>
                     </div>
                </Col>
                </div>
            </Row>

            <Row>
                   
                <Col xs={12} md={1}>
                <div>
                  <img src={baladna} style={{width: 100, height: 100}}/>
                </div>
                </Col>
                
                <Col xs={12} md={11}>      
                  
                </Col>
             
          </Row>

            <Container>
            <Row style={{marginTop:40}}>
                <Col xs={12} md={12} >
                <div style={{fontSize:25, fontWeight:'bold', textAlign:'center'}}>Available Drivers</div>
         <Table style={{marginTop:40 }} striped bordered hover>
            <thead>
            <tr>
            <th>#</th>
            <th>Driver Name</th>
            <th>Mobile Number</th>
            <th>Status</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Trip Status</th>
            <th></th>
            </tr>
            </thead>
            <tbody>
        {
          details.map((row, index) => {
              return(
            <tr>
            <td>{index+1}</td>
            <td>{row.ds_drivername}</td>
            <td>{row.ds_driverphoneno}</td>
            <td>{row.ds_driverstatus == 0 ? "Available" : "Un Available"}</td>
            <td>{row.ds_dutyentrytime}</td>
            <td>{row.ds_dutycompletiontime}</td>
            <td>{row.ds_dutystatus == 2 ? "completed" : "Not Started"}</td>
            <td>
            {row.ds_driverstatus == 0 ?
            <Button onClick={() => activate(row.ds_id,row.ds_driver_id)} variant="primary" style={{height: 25, paddingTop: 2, fontSize: 13, border: 'none', boxShadow: 'none'}}>Activate</Button>:
            <Button  variant="primary" style={{height: 25, paddingTop: 2, fontSize: 13, border: 'none', boxShadow: 'none'}}>Deactive</Button>}                  
            </td>
            </tr>
             )
                                               
            })
        }
            </tbody>
        </Table>
        </Col>
            </Row>
            </Container>
    </div>
  )
}

export default Outlet