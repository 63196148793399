import './dashboard.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Form} from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {useHistory} from 'react-router-dom';
import { BsFillPeopleFill, BsFillEyeFill} from "react-icons/bs";
import ListBox from 'react-listbox';
import 'react-listbox/dist/react-listbox.css';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LineChart,RadialBarChart,RadialBar, Line, Brush, AreaChart, Area, ResponsiveContainer } from 'recharts';

const data = [
    {name: 'Jan', uv: 100, pv: 2400, amt: 2400},
    {name: 'Feb', uv: 300, pv: 2400, amt: 2400},
    {name: 'Mar', uv: 500, pv: 2400, amt: 2400},
    {name: 'Apr', uv: 200, pv: 2400, amt: 2400},
    {name: 'May', uv: 400, pv: 2400, amt: 2400},
    {name: 'Jun', uv: 300, pv: 2400, amt: 2400}];

function DashboardMetro(props) {
    let history = useHistory();
    const [source, setSource] = useState([]);
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [details, setDetails] = React.useState([])
    const [sday, setSday] = useState("");
    const [smonth, setSmonth] = useState("");
    const [syear, setSyear] = useState("");
    const [show, setShow] = useState("");


    const driverChange = (event) => {
       setShow(event.target.value);
      };

    useEffect(() => {
        getdata()
        getdata1()
        drivers()
        list()
        
    }, []);

    const getdata = () => {
        fetch(global.url + "viewAllCompany", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log('responseJson', responseJson)
            setSource(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getdata1 = () => {
       
      var data = {
          "company_id" : 25,
          "type" :'superadmin',
      }

     
      fetch(global.url + "limousineDashboard", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
         console.log("response", responseJson)
         setDay(responseJson.data.todayrunning)
         setMonth(responseJson.data.monthlyrunning)
         setYear(responseJson.data.yearlyrunning)
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const drivers = () => {
       
    var data = {
        "company_id" : 25,
       
    }

   
    fetch(global.url + "driverslistonlimousine", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
       console.log("drivers", responseJson)
       setDetails(responseJson.data)
       list()
    })
    .catch((error)=>{
        console.log(error)
    })
}

const list = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))  
    console.log("userdata", userdata)
    var data = {
        "company_id" : 25,
        "type" :'superadmin',
        "driver_id":show,
    }

    console.log("data1", data)
    fetch(global.url + "limousineDashboard", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
       console.log("list", responseJson)

       setSday(responseJson.data.driverdayrunning)
       setSmonth(responseJson.data.drivermonthlyrunning)
       setSyear(responseJson.data.driveryearlyrunning)
       
    })
    .catch((error)=>{
        console.log(error)
    })
}

  return (
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden',}}>
                <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Dashboard</p>
                </div>

           <div style={{padding:30}}>
                <Row>
                    <span><strong>Total Company Running</strong></span>
                </Row>

                <Row style={{marginTop: 30}}>
                    <Col sm={3}>
                       
                        <div className="card w_data_1" style={{background: '#04BE5B'}}>
                            <div className="card-body" style={{background: '#04BE5B'}}>
                                <div style={{position: 'absolute', right: 0, top: 0, }}>
                                <a href="reports/salesreport">
                                    <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                </a>
                                {/* <a href="transaction/Sales/add">
                                    <BiPencil style={{color: '#fff',  marginRight: 10, marginTop: 5}}/>
                                </a> */}
                                </div>
                                <span style={{color: '#fff', marginTop: 10}}><strong>DAILY RUNNING</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3"> {day} </h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}> 
                       
                        <div className="card w_data_1" style={{background: '#46b6fe'}}>
                            <div className="card-body" style={{background: '#46b6fe'}}>
                                <span style={{color: '#fff'}}><strong>MONTHLY RUNNING</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3"> {month}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1" style={{background: '#04BE5B'}}>
                            <div className="card-body" style={{background: '#04BE5B'}}>
                                <span style={{color: '#fff'}}><strong>THIS YEAR RUNNING</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3"> {year}</h4>
                            </div>
                        </div>
                    </Col>
                    
                </Row>
            </div>

                <div style={{margin: 30}}>

                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p className="onboard-companies" style={{color: '#000', fontSize: 16}}>USERS</p>
                        <Button variant="primary" style={{height: 25, paddingTop: 3, fontSize: 13, border: 'none', fontWeight: 'bold', boxShadow: 'none', backgroundColor: '#7e77fe', color: '#fff'}}>
                            <a style={{textDecoration: 'none', color: '#fff'}} href="/ledgerdetailsmetro">Ledger Details</a>
                        </Button>
                    </div>
                  
                    <Row>
                        <Col md={4}>
                        
                            
                                {/* <FormControl style={{marginTop: 5}} sx={{width: '100%'}}>
                                 
                            <select id="users" name="users">
                           
                            {
                        details.map((row, index) => {
                            return(
                                <option value="volvo">{row.user_name}</option>
                                )
                                               
                            })
                        }   
                            </select>
                                </FormControl> */}
                           
                                <FormControl sx={{width: '100%' }}>
                                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Driver Name</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value={show}
                                                        label="Age"
                                                        onChange={driverChange}
                                                        size="small"
                                                    >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {details.map((row, index) => {
                                                        return (
                                                        <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                                    )})}
                                                    </Select>
                                            </FormControl>
                        </Col>
                    </Row>

                    <div style={{marginTop: 30}}>
                <Row>
                <div style={{display: 'flex'}}>
                                
                    <div>
                        <span><strong>Total User Running</strong></span>
                    </div>
                    <div>
                        <Button onClick={() => list()} variant="primary" style={{height: 25, paddingTop: 3, fontSize: 15, border: 'none', fontWeight: 'bold', boxShadow: 'none', backgroundColor: '#7e77fe', color: '#fff', marginLeft:10}}>
                           show
                        </Button>
                     </div>
                </div>
                </Row>

                <Row style={{marginTop: 20}}>
                    <Col sm={3}>
                       
                        <div className="card w_data_1" style={{background: '#04BE5B'}}>
                            <div className="card-body" style={{background: '#04BE5B'}}>
                                <div style={{position: 'absolute', right: 0, top: 0, }}>
                                <a href="reports/salesreport">
                                    <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                </a>
                                {/* <a href="transaction/Sales/add">
                                    <BiPencil style={{color: '#fff',  marginRight: 10, marginTop: 5}}/>
                                </a> */}
                                </div>
                                <span style={{color: '#fff', marginTop: 10}}><strong>DAILY RUNNING</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">{sday}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}> 
                       
                        <div className="card w_data_1" style={{background: '#46b6fe'}}>
                            <div className="card-body" style={{background: '#46b6fe'}}>
                                <span style={{color: '#fff'}}><strong>MONTHLY RUNNING</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">{smonth}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1" style={{background: '#04BE5B'}}>
                            <div className="card-body" style={{background: '#04BE5B'}}>
                                <span style={{color: '#fff'}}><strong>THIS YEAR RUNNING</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">{syear}</h4>
                            </div>
                        </div>
                    </Col>
                    
                </Row>
            </div>

                    <Row>
                        {source.map((item, index) => (
                            <Col xs={12} md={6}>
                            <a style={{color:'unset', textDecoration: 'none'}}>
                            <div className="second-box" style={{marginTop: 20}}>
                                <Row>
                                    <Col xs={12} sm={10}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <p className="box-para1">{item.company_name}</p>
                                        </div>
                                        <Row>
                                            <Col xs={6}>
                                                <Row>
                                                    <Col md={8}>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Today Sale Amount</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Total Sale Amount</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Today Commision</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Total Commision</p>
                                                    </Col>
                                                    <Col md={4}>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={6}>
                                            <Row>
                                                    <Col md={8}>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Today Cancel Order</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Today Pending Order</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>Total Order</p>
                                                    </Col>
                                                    <Col md={4}>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                        <p style={{fontSize: 13, fontWeight: 500}}>:</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            </a>
                        </Col>
                        ))}
                    </Row>
                </div>
                <div style={{margin: 30}}>
                <Row style={{marginTop:40}}>
                        <Col xs={12} md={7}>
                            <span><strong>EMPLOYEES STATISTICS</strong></span>
                            <div style={{marginTop: 30, overflowX: 'auto', padding: 15, backgroundColor: '#fff'}}>
                                <BarChart width={600} height={300} data={data}>
                                    <XAxis gridLine dataKey="name" stroke="#8884d8" />
                                    <YAxis gridLine={false} />
                                    <Tooltip wrapperStyle={{ width: 100, backgroundColor: '#d3d3d3' }} />
                                    <Legend width={100} wrapperStyle={{ top: 10, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <Bar dataKey="uv" fill="#38B0DE" barSize={30} />
                                </BarChart>
                            </div>
                        </Col>
                    </Row>
                    </div>
            </Paper>
        </div>
  );
}

export default DashboardMetro;
