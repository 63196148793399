import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Modal  } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import { FaArrowCircleRight } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { useHistory, useParams } from "react-router-dom";
import Loader from '../loader/loader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import './login.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Signup() {

  let history = useHistory();
  const vertical = "top"
  const horizontal = "center"
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [location, setLocation] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const [nameErr, setNameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [mobileErr, setMobileErr] = useState({});
  const [locationErr, setLocationErr] = useState({});
  const [passwordErr, setPasswordErr] = useState({});
  const [confirmErr, setConfirmErr] = useState({});
 

  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrormessage] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
}

const formValidation = (e) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const re = /^[0-9\b]+$/;
    const nameErr = {};
    const emailErr = {};
    const mobileErr = {};
    const locationErr = {};
    const passwordErr = {};
    let isValid = true;

    if(name.trim().length == 0){
        nameErr.namerequired = 'Name is required'
    }
    if(email.trim().length == 0){
        emailErr.emailRequired = 'Email id is required';
        isValid = false;
    } 
    if (!regex.test(email)){
        emailErr.emailRequired = 'Email id is not valid';
        isValid = false;
    }
    if(mobile.trim().length == 0){
        mobileErr.mobileRequired = 'Mobile Number is required';
        isValid = false;
    }
    if (!re.test(mobile)){
        mobileErr.mobileRequired = 'Invalid Mobile Number';
        isValid = false;
    }
    if(location.trim().length == 0){
        locationErr.locationRequired = 'Mobile Number is required';
        isValid = false;
    }
    if(password.trim().length == 0){
        passwordErr.passwordRequired = 'Password is required';
        isValid = false;
    }
    if(password.trim().length < 6){
        passwordErr.passwordRequired = 'Password is too short (Min 6 Letters)';
        isValid = false;
    }
    if(password.trim().length > 20){
        passwordErr.passwordRequired = 'Password is too long (Max 20 Letters)';
        isValid = false;
    }
   
    if(name.trim().length != 0 && email.trim().length != 0 && mobile.trim().length != 0 && location.trim().length != 0 &&
    password.trim().length != 0 && regex.test(email) && re.test(mobile))  {
        getData()
    }
    setNameErr(nameErr);
    setEmailErr(emailErr);
    setMobileErr(mobileErr);
    setLocationErr(locationErr);
    setPasswordErr(passwordErr);
    return isValid  
}
const handleClose = (event, reason) => {
  if(reason === 'clickaway'){
      return;
  }
  setOpen(false);
}


const getData = () => {
  setLoader(true)
  var data = {
      "fullname" : name,
      "phone" : mobile,
      "email" : email,
      "location" : location,
      "password" : password,
  }
  fetch(global.url + "driverSignUp ", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          'APPKEY': 'erp6129'
      },
      body: JSON.stringify(data)
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("response signup", responseJson)  
      setLoader(false)
      if(responseJson.error == false){
          var data = responseJson.data
          var data1 = data.user_data
          console.log("data", data1)
          history.push('/loginlimousine')
         
      }else{
        setOpen(true)
        setErrormessage(responseJson.message)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
}


  return (
    
    <div className="App">
      <header className="Login-component">
            <Container fluid>
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                        <div className="login-box">
                            <div className="box-inside">
                                <div className="login-title">
                                    <strong>NASSCRIPT
                                        
                                    </strong>
                                </div>
                                <div class="frm-title">
                                    Welcome back! Please login to continue web
                                </div>

                                <div style={{marginTop:20}}>
                            <Form>
                              <Form.Group className="mb-1" controlId="formBasicEmail">
                                  <Form.Label>Fullname</Form.Label>
                                  <Form.Control 
                                      type="text"
                                      className='shadow12' 
                                      value={name}
                                      onChange={(e) => {setName(e.target.value)}} 
                                      placeholder='Enter Your Fullname' />
                              </Form.Group>
                              {Object.keys(nameErr).map((key) => {
                                  return <div style={{color: 'red', fontSize: 12}}>{nameErr[key]}</div>
                              })}
                          </Form>
                            </div>

                            <div style={{marginTop:20}}>
                            <Form>
                              <Form.Group className="mb-1" controlId="formBasicEmail">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control 
                                      type="email"
                                      className='shadow12' 
                                      value={email}
                                      onChange={(e) => {setEmail(e.target.value)}} 
                                      placeholder='Enter Your Email Address' />
                              </Form.Group>
                              {Object.keys(emailErr).map((key) => {
                                  return <div style={{color: 'red', fontSize: 12}}>{emailErr[key]}</div>
                              })}
                          </Form>
                            </div>

                            <div style={{marginTop:20}}>
                            <Form>
                              <Form.Group className="mb-1" controlId="formBasicEmail">
                                  <Form.Label>Mobile Number</Form.Label>
                                
                                  <Form.Control 
                                      type="phone"
                                      className='shadow12' 
                                      value={mobile}
                                      onChange={(e) => {setMobile(e.target.value)}} 
                                      placeholder='Enter Your Mobile Number' />
                              </Form.Group>
                              {Object.keys(mobileErr).map((key) => {
                                return <div style={{color: 'red' , fontSize: 12}}>{mobileErr[key]}</div>
                              })}
                                                      
                          </Form>
                            </div>

                            <div style={{marginTop:20}}>
                            <Form>
                              <Form.Group className="mb-1" controlId="formBasicEmail">
                                  <Form.Label>Location</Form.Label>
                                  <Form.Control 
                                      type="text"
                                      className='shadow12' 
                                      value={location}
                                      onChange={(e) => {setLocation(e.target.value)}} 
                                      placeholder='Enter Your Location' />
                              </Form.Group>
                              {Object.keys(locationErr).map((key) => {
                                  return <div style={{color: 'red', fontSize: 12}}>{locationErr[key]}</div>
                              })}
                          </Form>
                            </div>
                            
                            <div style={{marginTop:20}}>
                            <Form>
                              <Form.Group className="mb-1" controlId="formBasicEmail">
                                  <Form.Label>Password</Form.Label>
                                  <Form.Control 
                                      type="password"
                                      className='shadow12' 
                                      value={password}
                                      onChange={(e) => {setPassword(e.target.value)}} 
                                      placeholder='Enter Your Password' />
                              </Form.Group>
                              {Object.keys(passwordErr).map((key) => {
                                  return <div style={{color: 'red', fontSize: 12}}>{passwordErr[key]}</div>
                              })}
                          </Form>
                            </div>


                            <div style={{marginTop:20}} >
                                {['checkbox'].map((type) => (
                                  <div key={`default-${type}`} className="mb-3">
                                    <Form.Check 
                                    type={type}
                                    id={`default-${type}`}
                                    label={'I agree to terms and privacy policy'}
                                    />
                                  </div>
                                ))}
                            </div>

                                <Form>
                                  
                                    <div >
                                        <div style={{paddingBottom: 20}}>
                                            {loader ? <Loader /> :
                                                <a onClick={onSubmit}
                                                     variant="primary" type="submit" style={{marginTop: 20, background: '#8a4cff', borderColor: '#8a4cff', color: '#fff', textDecoration: 'none', width: 100, padding: 5, borderRadius: 5}}>
                                                    Signup
                                                    <FaArrowCircleRight color="#fff" size={12} className="lefticon"/>
                                                </a>
                                            }
                                        </div>
                                        <div style={{marginTop:20, textAlign:'center'}}>Have an account? <a onClick={() => history.push('/loginlimousine')} style={{color:'#8a4cff', cursor: 'pointer'}}>Login</a></div>
                                    </div>
                                </Form>
                                <div class="frm-footer"><span className="nastext">NASScript</span> © 2021.</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
           
           <Modal
              size="sm"
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton >
                <Modal.Title id="example-modal-sizes-title-lg">
                    <p style={{fontSize: 18, marginBottom: 'unset', marginTop: 'unset'}}>Forgot Password</p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                            <TextField  value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                        </FormControl>
                        {loader ? <Loader /> :
                        <Button  variant="contained" style={{marginTop: 20}}>Submit</Button>
                        }
                  </div>
              </Modal.Body>
           </Modal>
      </header>
      <Snackbar anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Signup







