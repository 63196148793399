
import React, {useState, useEffect} from 'react';
import { Row, Col, Form  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { MultiSelect } from "react-multi-select-component";




function WizardFormFirstPage(props) {
  const childvalue = (latvalue, longvalue) => {
    props.mapValues(latvalue, longvalue)
  }
  return (
        <div>
            <Row>
                <Col xs={12} >
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="empCode"
                            onChange={props.updateform}
                            value={props.form.empCode} id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="empAccount"
                        onChange={props.updateform}
                        value={props.form.empAccount} id="outlined-basic" label="Employee name" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Sub Group</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.ledger}
                                label="Age"
                                onChange={props.ledgerChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.ledgerList.map((item, index) => (
                                  <MenuItem value={item.group_id}>{item.group_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Designation</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.destination}
                                label="Age"
                                onChange={props.destinationChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.destinationList.map((item, index) => (
                                  <MenuItem value={item.destination_id}>{item.destination_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="address1"
                        onChange={props.updateform}
                        value={props.form.address1} id="outlined-basic" label="Address 1" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="address2"
                        onChange={props.updateform}
                        value={props.form.address2} id="outlined-basic" label="Address 2" variant="outlined"  size="small"/>
                    </FormControl>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="address3"
                        onChange={props.updateform}
                        value={props.form.address3} id="outlined-basic" label="Address 3" variant="outlined"  size="small"/>
                    </FormControl>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.country}
                                label="Age"
                                onChange={props.countryChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.countryList.map((item, index) => (
                                  <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.state}
                                label="Age"
                                onChange={props.stateChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.stateList.map((item, index) => (
                                  <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">District</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.district}
                                label="Age"
                                onChange={props.districtChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.districtList.map((item, index) => (
                                  <MenuItem value={item.district_id}>{item.district_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="gstin"
                                onChange={props.updateform}
                                value={props.form.gstin} id="outlined-basic" label="GSTIN" variant="outlined"  size="small"/>
                        </FormControl>

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="mobile"
                                onChange={props.updateform}
                                value={props.form.mobile} id="outlined-basic" label="Phone/Mob" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="email"
                                onChange={props.updateform}
                                value={props.form.email} id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="website"
                                onChange={props.updateform}
                                value={props.form.website} id="outlined-basic" label="Website" variant="outlined"  size="small"/>
                        </FormControl>

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="whatsapp"
                                onChange={props.updateform}
                                value={props.form.whatsapp} id="outlined-basic" label="Whatsap" variant="outlined"  size="small"/>
                        </FormControl>

                        <Col xs={12} style={{marginTop: 20}}>
                          {/* <pre>{JSON.stringify(selected)}</pre> */}
                          <p>Branches</p>
                            <MultiSelect
                              options={props.branchlist}
                              value={props.selected}
                              onChange={props.setSelected}
                              labelledBy="Select"
                            />
                          </Col>
                          <label style={{fontSize: 10}}>Required</label>

                        <FormControl sx={{ mt: 4}} component="fieldset">
                            <RadioGroup
                                defaultValue="credit"
                                name="radio-buttons-group"
                                onClick={props.radioChange}
                            >
                                <FormControlLabel value="credit"  control={<Radio checked={props.decr == 0?  false: true}/>} label="Credit" color = '#999'  />
                                <FormControlLabel value="debit" color= '#999' control={<Radio checked={props.decr == 0?  true: false}/>} label="Debit" />
                            </RadioGroup>
                        </FormControl>

                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField name="openBalnce"
                                onChange={props.updateform}
                                value={props.form.openBalnce} type="number" id="outlined-basic" label="Opening Balance" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>

                        <FormControl sx={{ mt:2,  width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={props.blocked == 0? false : true} onChange={e =>props.checkchange(e)} />
                            } label="Blocked" />
                        </FormControl>

                        {/* <div style={{marginBottom: 70, marginTop: 30}}>
                            <Map
                                google={props.google}
                                center={{lat: props.latitude, lng: props.longitude}}
                                height='300px'
                                zoom={15}
                                parentCallback={childvalue}
                            />
                        </div> */}
                </Col>   
            </Row>
        </div>
  );
}

export default WizardFormFirstPage;
