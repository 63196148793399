import React, {useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table';
import {Container, Row, Col, Form, Button} from "react-bootstrap"
import baladna from '../../assets/images/baladna11.png'
import { useHistory, useParams } from "react-router-dom";
import { BsFillPeopleFill, BsFillEyeFill} from "react-icons/bs";
function Driver() {

  let history = useHistory();
    const [but, setBut] = React.useState(false)
    const [details, setDetails] = React.useState([])
    const [task, setTask] = React.useState("")
    const [id, setId] = React.useState("")
    const [dsid, setDsid] = React.useState("")

   
    const logout = () => {
      history.push('/onboard')
      localStorage.removeItem("user")
  }

    useEffect(() => { 
      getdata()
    }, []);

    const getdata = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      console.log("userdata", userdata)
    var data = {
        "company_id" : 25,
        "user_id" :userdata.user_id
    }

    console.log("data1", data)
    fetch(global.url + "viewOnDriverPage", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
       console.log("responseJson", responseJson)
       setDetails(responseJson.data)
      //  setTask(responseJson.data.task_location)
      //  setDsid(responseJson.data[0].ds_id)
      //  setId(responseJson.data.ds_dutystatus)
    })
    .catch((error)=>{
        console.log(error)
    })
    }
      
 

  useEffect(() => {
    console.log("dsid", id)
  },[id])
 
  const finished = () => {
    setBut(true)
  }

  const duty = (id) => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    
    var data = {
      "company_id": 25 ,
      "user_id": userdata.user_id,
      "task_id": id,
      
    }
    console.log("data", data)
    fetch(global.url + "acceptingTaskDriver", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((responseJson) => {
        console.log("duty", responseJson)
        getdata()
        
    })
    .catch((error) => {
        console.log(error)
    })
}

const onduty = (id) => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    console.log("check")
    var data = {
      "company_id": 25 ,
      "user_id": userdata.user_id,
      "task_id": id,
     
    }
    console.log("data", data)
    fetch(global.url + "finishingTaskDriver", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((responseJson) => {
        console.log("onduty", responseJson)
       
        getdata()
    })
    .catch((error) => {
        console.log(error)
    })
}
  return (
    <div> 
      
              <div style={{backgroundColor: "#8A1538"}}>
             
                <Row>
                <Col xs={12} md={12}>
                 
                    <div style={{display: "flex",justifyContent: "space-between"}}>
                      {/* <div>
                        <img src={baladna} style={{width: 100, height: 100}}/>
                      </div> */}
                     
                      <Col xs={12} md={11}>
                      <div style={{fontSize:35, fontWeight:'bold', color: '#ffffff', textAlign: 'center', marginTop: 28, marginBottom:10}}>DRIVERS SAFARIMALL <sapan style={{fontSize:20}}>(Abu hamoor)</sapan></div>
                      </Col>
                      <Col xs={12} md={1}>
                      <div style={{marginTop:35}} href="javascript:void(0)" onClick={logout}> 
                        <img src={require("../../assets/images/icons/logout.svg").default} style={{width: 45, height: 45, marginTop:1}} />                     
                        </div>
                      </Col>
                      
                              
                        
                        </div>
                   
                </Col>
                </Row>
               
              </div>

              <Row>
                
                    
                      <Col xs={12} md={1}>
                      <div>
                        <img src={baladna} style={{width: 100, height: 100}}/>
                      </div>
                      </Col>
                      
                      <Col xs={12} md={11}>      
                        
                      </Col>
                   
               
                </Row>


          
            <Container>

            <div style={{marginTop: 30}}>
                <Row>
                <div style={{display: 'flex'}}>
                                
                    <div>
                        <span><strong>Total Running</strong></span>
                    </div>
                    <div>
                        <Button  variant="primary" style={{height: 25, paddingTop: 3, fontSize: 15, border: 'none', fontWeight: 'bold', boxShadow: 'none', backgroundColor: '#7e77fe', color: '#fff', marginLeft:10}}>
                           show
                        </Button>
                     </div>
                </div>
                </Row>

                <Row style={{marginTop: 20}}>
                    <Col sm={3}>
                       
                        <div className="card w_data_1" style={{background: '#04BE5B'}}>
                            <div className="card-body" style={{background: '#04BE5B'}}>
                                <div style={{position: 'absolute', right: 0, top: 0, }}>
                                <a href="reports/salesreport">
                                    <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                </a>
                                {/* <a href="transaction/Sales/add">
                                    <BiPencil style={{color: '#fff',  marginRight: 10, marginTop: 5}}/>
                                </a> */}
                                </div>
                                <span style={{color: '#fff', marginTop: 10}}><strong>DAILY RUNNING</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">3</h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}> 
                       
                        <div className="card w_data_1" style={{background: '#46b6fe'}}>
                            <div className="card-body" style={{background: '#46b6fe'}}>
                                <span style={{color: '#fff'}}><strong>MONTHLY RUNNING</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">3</h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1" style={{background: '#04BE5B'}}>
                            <div className="card-body" style={{background: '#04BE5B'}}>
                                <span style={{color: '#fff'}}><strong>THIS YEAR RUNNING</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">3</h4>
                            </div>
                        </div>
                    </Col>
                    
                </Row>
            </div>


              
            <Row style={{marginTop:40}}>
                <Col xs={12} md={12} >
                <div style={{fontSize:25, fontWeight:'bold', textAlign:'center'}}>Drivers Tasks</div>
            <Button onClick={() => history.push('/checkin')} variant="primary" style={{height: 25, paddingTop: 2, fontSize: 13, border: 'none', boxShadow: 'none'}}>checkin</Button>
                
         <Table style={{marginTop:40}} striped bordered hover responsive>
            <thead>
            <tr>
            <th>#</th>
            <th>Task</th>
            <th>From</th>
            <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {
          details.map((row, index) => {
              return(
                <tr>
                <td>{index+1}</td>
                <td>{row.outlet_name}</td>
                <td>{row.task_location}</td>
                
         
                <td>
                 
                  { row.ds_dutystatus == 1 ?
                     <Button onClick={() => onduty(row.ds_id)}  variant="primary" style={{height: 25, paddingTop: 2, fontSize: 13, border: 'none', boxShadow: 'none'}}>On Duty</Button>:
                     row.ds_dutystatus == 2 ?
                     <Button variant="primary" style={{height: 25, paddingTop: 2, fontSize: 13, border: 'none', boxShadow: 'none'}}>finished</Button>:
                     <Button onClick={() => duty(row.ds_id)}  variant="primary" style={{height: 25, paddingTop: 2, fontSize: 13, border: 'none', boxShadow: 'none'}}>Duty</Button>                        
                  }
                    
                </td>
                 
                   
                </tr>
             )
                                               
            })
        }
            </tbody>
        </Table>
        </Col>
            </Row>
            </Container>
    </div>
  )
}

export default Driver